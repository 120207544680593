import React from 'react'

const Articles = () => {
  return (
    <div>
      <h1>Articles</h1>
    </div>
  )
}

export default Articles
